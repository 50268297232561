import keyMirror from "keymirror";

export default keyMirror({
  REQUEST_PROJECT_BOARD: null,
  RECEIVE_PROJECT_BOARD: null,
  ERROR_REQUEST_PROJECT_BOARD: null,
  RECEIVE_PROJECT: null,
  RECEIVE_USERS: null,
  RECEIVE_STORIES: null,
  RECEIVE_PAST_ITERATIONS: null,
  REQUEST_PAST_STORIES: null,
  RECEIVE_PAST_STORIES: null,
  ERROR_REQUEST_PAST_STORIES: null,
  CREATE_STORY: null,
  ADD_STORY: null,
  CLONE_STORY: null,
  RECEIVE_HISTORY: null,
  LOAD_HISTORY: null,
  TOGGLE_STORY: null,
  EDIT_STORY: null,
  UPDATE_STORY: null,
  RECEIVE_HISTORY_ERROR: null,
  CLOSE_HISTORY: null,
  UPDATE_STORY_SUCCESS: null,
  SORT_STORIES_SUCCESS: null,
  STORY_FAILURE: null,
  SET_LOADING_STORY: null,
  ADD_TASK: null,
  REMOVE_TASK: null,
  TOGGLE_TASK: null,
  DELETE_STORY_SUCCESS: null,
  ADD_NOTE: null,
  DELETE_NOTE: null,
  ADD_LABEL_TO_PROJECT: null,
  ADD_LABEL: null,
  DELETE_LABEL: null,
  ADD_NOTIFICATION: null,
  REMOVE_NOTIFICATION: null,
  SEARCH_STORIES_SUCCESS: null,
  CLOSE_SEARCH: null,
  CLOSE_EPIC_COLUMN: null,
  HIGHLIGHT_STORY: null,
  LOADING_SEARCH: null,
  REVERSE_COLUMNS: null,
  TOGGLE_COLUMN_VISIBILITY: null,
  REVERT_TO_CALCULATED_VELOCITY: null,
  SIMULATE_SPRINT_VELOCITY: null,
  OPTIMISTICALLY_UPDATE: null,
});
